import classNames from "classnames"
import React, { useRef } from "react"

import { useTranslation } from "@bounce/i18n"
import type { SlideInRef } from "@bounce/web-components"
import { Link, NavbarLink, NavbarMobile as BaseNavbarMobile } from "@bounce/web-components"

import { LanguageSelectorModal } from "./LanguageSelector/LanguageSelectorModal"
import { useLanguages } from "./LanguageSelector/useLanguages"
import type { MobileAndDesktopProps } from "./types"

import ChevronDownIcon from "@bounce/assets/icons/ChevronDown.svg"
import LuggageIcon from "@bounce/assets/icons/Luggage.svg"
import PlaneIcon from "@bounce/assets/icons/Plane.svg"
import BounceIcon from "@bounce/assets/images/logo/logo.svg"

export type NavbarMobileProps = {
  authenticated: boolean
} & MobileAndDesktopProps

export const NavbarMobile = ({ alternates, links, languages, authenticated, className }: NavbarMobileProps) => {
  const { t } = useTranslation()
  const { currentOption } = useLanguages({ alternates, languages })
  const languagesRef = useRef<SlideInRef>(null)
  const { account, auth, mobile } = links
  const userLink = authenticated ? account : auth

  const openLanguageModal = () => {
    if (!languagesRef.current) return
    languagesRef.current.open()
  }

  return (
    <BaseNavbarMobile className={className}>
      <div className="mb-7 flex w-full flex-col items-center gap-y-7 [&>*]:!title4 [&>*]:font-bold [&>*]:text-white">
        <NavbarLink as={Link} href="/s" children={t("cmp.generic.navbar.storeBags", "Store bags")} />
        <NavbarLink as={Link} href="/packages" children={t("cmp.generic.navbar.packagePickup", "Package pickup")} />
        <NavbarLink as={Link} href="/partner" children={t("cmp.generic.navbar.partner", "Become a Partner")} />
      </div>
      <div className="mt-7 flex w-full flex-col items-center gap-y-7 [&>*]:text-neutral-300">
        {mobile.map((props, key) => (
          <NavbarLink as={Link} key={key} className="!text-neutral-300" {...props} />
        ))}
      </div>
      <div className="mt-12 flex w-full flex-col items-center justify-center">
        <NavbarLink as="a" rel="nofollow" {...userLink} className="!text-neutral-300" />
        <button
          onClick={openLanguageModal}
          className={classNames(
            "label1 text-white",
            "flex items-center justify-center",
            "mb-[74px] mt-7 px-7 py-3 ",
            "rounded-[32px] border border-white",
          )}>
          <span>{currentOption.label}</span>
          <ChevronDownIcon className="ml-3 size-4" />
        </button>
      </div>
      <div className="flex flex-row justify-center gap-x-4">
        <PlaneIcon className="size-6 text-white" />
        <BounceIcon className="size-6 text-white" />
        <LuggageIcon className="size-6 text-white" />
      </div>
      <LanguageSelectorModal alternates={alternates} languages={languages} languagesRef={languagesRef} />
    </BaseNavbarMobile>
  )
}
