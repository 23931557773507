import type { ParsedUrlQuery } from "querystring"

import type { NextRouter } from "next/router"
import { useRouter } from "next/router"

import type { Country, Locale } from "@bounce/i18n"
import { useTranslation } from "@bounce/i18n"

import type { GetStoreLocationQuery } from "./bounce/bounceSdk.types.gen"
import type { CityData } from "./bounce/cities"
import { config } from "../config"
import { HOMEPAGE_ONLY_LANGUAGES, IN_LANGUAGE_URL_LANGUAGES } from "../utils/i18n/locale"
import { getPathTranslation } from "../utils/i18n/pathTranslations"

type GetCityUrlPathArgs = {
  language: Locale["language"]
  city: Pick<CityData, "slug" | "translations">
}

type GetPoiUrlPathArgs = {
  language: Locale["language"]
  city: Pick<CityData, "slug" | "translations">
  poi: Pick<CityData, "slug" | "translations">
}

type GetStoreUrlPathArgs = {
  store: Pick<GetStoreLocationQuery["store"], "slug"> & {
    city: Pick<CityData, "slug">
  }
}

export const DEFAULT_COUNTRY = "US"
const DEFAULT_COUNTRY_PHONE = "1"

export const getCityUrlDefaultPath = (slug: CityData["slug"]) => `/city/${slug}`

export const getCityUrlPath = ({ language, city: { slug, translations } }: GetCityUrlPathArgs) => {
  const defaultPath = getCityUrlDefaultPath(slug)
  if (!IN_LANGUAGE_URL_LANGUAGES.includes(language)) {
    return defaultPath
  }

  const translatedSlug = translations.slug?.find(translation => translation?.language === language)
  if (!translatedSlug) {
    return defaultPath
  }

  return `/${getPathTranslation("luggage-storage", language)}/${translatedSlug.text}`
}

export const getPoiUrlPath = ({
  language,
  city: { translations: cityTranslations, slug: citySlug },
  poi: { translations: poiTranslations, slug: poiSlug },
}: GetPoiUrlPathArgs) => {
  const defaultPath = `/city/${citySlug}/${poiSlug}`
  if (!IN_LANGUAGE_URL_LANGUAGES.includes(language)) {
    return defaultPath
  }

  const translatedPoiSlug = poiTranslations.slug?.find(poiTranslation => poiTranslation?.language === language)
  if (!translatedPoiSlug) {
    return defaultPath
  }

  const translatedCitySlug = cityTranslations.slug?.find(cityTranslation => cityTranslation?.language === language)
  if (!translatedCitySlug) {
    return defaultPath
  }

  return `/${getPathTranslation("luggage-storage", language)}/${translatedCitySlug.text}/${translatedPoiSlug.text}`
}

export const getEventsCityUrlDefaultPath = ({ slug }: Pick<CityData, "slug">) => `/events/${slug}`

export const getEventsUrlDefaultPath = () => "/events"

export const getStoreUrlPath = ({ store }: GetStoreUrlPathArgs) => `/stores/${store.city.slug}/${store.slug}`

export const useCityUrl = ({ city }: Pick<GetCityUrlPathArgs, "city">) => {
  const { i18n } = useTranslation()
  const { getUrl: getBaseUrl } = useUrl()

  const getUrl = ({ absolute = true, language = i18n.language } = {}) =>
    getBaseUrl({
      path: getCityUrlPath({ language, city }),
      absolute,
      locale: language,
    })

  return {
    getUrl,
    absolutePath: getUrl({ absolute: true }),
  }
}

export const usePoiUrl = ({ city, poi }: Pick<GetPoiUrlPathArgs, "city" | "poi">) => {
  const { i18n } = useTranslation()
  const { getUrl: getBaseUrl } = useUrl()

  const getUrl = ({ absolute = true, language = i18n.language } = {}) =>
    getBaseUrl({
      path: getPoiUrlPath({ language, city, poi }),
      absolute,
      locale: language,
    })

  return {
    getUrl,
    absolutePath: getUrl({ absolute: true }),
  }
}

export const useHomeUrl = () => {
  const { i18n } = useTranslation()
  const { getUrl: getBaseUrl } = useUrl()

  const getUrl = ({ absolute = true, language = i18n.language } = {}) =>
    getBaseUrl({
      path: "/",
      absolute,
      locale: language,
    })

  return {
    getUrl,
    absolutePath: getUrl({ absolute: true }),
  }
}

export const useStoreUrl = ({ store }: Pick<GetStoreUrlPathArgs, "store">) => {
  const { i18n } = useTranslation()
  const { getUrl: getBaseUrl } = useUrl()

  const getUrl = ({ absolute = true, language = i18n.language } = {}) =>
    getBaseUrl({
      path: getStoreUrlPath({ store }),
      absolute,
      locale: language,
    })

  return {
    getUrl,
    absolutePath: getUrl({ absolute: true }),
  }
}

export const useUrl = () => getUrlFromRouter(useRouter())

export const getUrlFromRouter = (router: NextRouter) => {
  const getUrl = ({ path = router.asPath, absolute = false, locale = router.locale, forceLocale = false } = {}) => {
    // remove url params and trailing slash
    let canonicalPath = path.split("?")[0]
    canonicalPath = canonicalPath !== "/" ? canonicalPath : ""

    const omitLocale = forceLocale
      ? false
      : locale === router.defaultLocale || HOMEPAGE_ONLY_LANGUAGES.includes(locale ?? "")
    const prefix = omitLocale ? "" : `/${locale}`
    const domain = absolute ? `https://${config.domain}` : ""
    const url = `${domain}${prefix}${canonicalPath}`

    return url === "" ? "/" : url
  }

  return {
    getUrl,
    absolutePath: getUrl({ absolute: true }),
  }
}

// Home slug is in format "US1" or "GB44" (country code + country phone code)
// Next.js can't handle multiple locales on the URL, See issue https://github.com/vercel/next.js/issues/52314
export const getHomeSlug = (country: Country | undefined) =>
  (country?.code ?? DEFAULT_COUNTRY) + (country?.phone ?? DEFAULT_COUNTRY_PHONE)

// Home slug is in format "US1" or "GB44" - we only want the country code part
export const getCountryCodeFromParams = (params: ParsedUrlQuery | undefined) => {
  const countryCodePhone = params?.countryCodePhone
  return typeof countryCodePhone === "string" ? countryCodePhone.replace(/\d+$/, "") : DEFAULT_COUNTRY
}
