import Cookies from "js-cookie"

type SetCookieOptions = Cookies.CookieAttributes & {
  key: string
  value: string
}

export const setCookie = ({ key, value, ...options }: SetCookieOptions) =>
  Cookies.set(key, value, {
    sameSite: "strict",
    strict: window.location.protocol === "https:",
    domain: process.env.NODE_ENV === "production" ? `.${window.location.hostname}` : undefined,
    ...options,
  })
