let _isInStorybook = false

export const setIsInStorybook = (isInStorybook: boolean) => {
  _isInStorybook = isInStorybook
}

/**
 * Returns whether the code is running in Storybook.
 * setIsInStorybook(true) must be called inside the preview file.
 */
export const isInStorybook = () => _isInStorybook
